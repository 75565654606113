import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import banner1 from "./../assests/images/carousel-1.jpg";
import banner2 from "./../assests/images/carousel-2.jpg";
import about from "./../assests/images/about1.jpeg";
import s1 from "./../assests/images/service1.png";
import s2 from "./../assests/images/service-2.jpg";
import s3 from "./../assests/images/service-3.jpg";
import { Link } from "react-router-dom";

const Index = () => {
  const owlRef = useRef(null);

  const options = {
    autoplay: true,
    loop: true,
    items: 1,
    nav: true,
    smartSpeed: 4000,
    autoplayTimeout: 7000,
    navText: [
      '<i class="bi bi-chevron-left"></i>',
      '<i class="bi bi-chevron-right"></i>',
    ],
  };

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="">
          <OwlCarousel
            className="owl-carousel header-carousel position-relative owl-theme"
            {...options}
            ref={owlRef}
          >
            <div className="position-relative     owl-carousel-item ">
              <img className="img-fluid" src={banner1} alt="carousel" />
              <div
                className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                style={{
                  backgroundColor: "rgba(6, 3, 21, 0.5)",
                }}
              >
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-10 col-lg-8">
                      <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                        Navigating Excellence in Maritime Services
                      </h5>
                      <h1 className="display-3 text-white animated slideInDown mb-4">
                        Trusted Partner in{" "}
                        <span className="text-primary">Maritime </span> Solution
                      </h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-2">
                        Our values—Ownership, Integrity, Teamwork, and Customer
                        Service—guide us in delivering exceptional maritime
                        services, ensuring trust, collaboration, and
                        satisfaction at every step.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="position-relative     owl-carousel-item ">
              <img className="img-fluid" src={banner2} alt="carousel" />
              <div
                className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                style={{
                  backgroundColor: "rgba(6, 3, 21, 0.5)",
                }}
              >
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-10 col-lg-8">
                      <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                        Ensuring Compliance, Safety, and Excellence
                      </h5>
                      <h1 className="display-3 text-white animated slideInDown mb-4">
                        Navigating{" "}
                        <span className="text-primary">Excellence </span> in
                        <span className="text-primary"> Maritime </span>{" "}
                        Services
                      </h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-2">
                        Our top priority is ensuring your operations meet the
                        highest standards of compliance and safety. Through
                        thorough audits and inspections, we guarantee that your
                        vessels are not only safe but also excel in all
                        regulatory aspects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <div className="container overflow-hidden py-5 px-lg-0">
        <div className="container about py-3 px-lg-0">
          <div className="row g-5 mx-lg-0 align-items-center pb-5">
            <div
              className="col-lg-7  px-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid"
                  src={about}
                  style={{
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-5 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-primary text-uppercase mb-3">About Us</h6>
              <h3 className="mb-3">
                Welcome to
                <span style={{ color: "var(--primary)" }}>
                  {" "}
                  Marinesure
                </span>{" "}
                Services
              </h3>
              <p className="mb-5">
                Established in 2023, Marinesure Serices Pvt Ltd is a budding
                enterprise poised to make its mark in the maritime industry.
                Anchored by a seasoned team of professionally qualified,
                experienced, and dedicated staff, our vision is to cement our
                reputation as a trusted maritime partner by consistently
                delivering exemplary maritime services, encompassing audits,
                inspections, and comprehensive training programs.
              </p>
              <Link to="/about" className="btn btn-secondary py-2 px-3 mb-5">
                Explore More
              </Link>
            </div>
          </div>
          <div className="col-12">
            <h2 className="mb-3">
              Innovative{" "}
              <span style={{ color: "var(--primary)" }}>
                Maritime Solutions{" "}
              </span>
              for a Dynamic Industry
            </h2>
            <p className="mb-3">
              Our core objectives revolve around nurturing strong client
              relationships, ensuring unwavering regulatory compliance, and
              expanding our service portfolio to address the dynamic needs of
              the market. We are committed to continuous enhancement through
              strategic investments in technology, ongoing professional
              development, and the establishment of robust quality management
              systems. Driven by an unwavering commitment to excellence and
              innovation, we aspire to amplify our brand presence, broaden our
              clientele base, and ascend as a leading global provider of
              maritime solutions.
            </p>
          </div>
        </div>
      </div>

      <div className="container-xxl" style={{ background: "#e5e5e5" }}>
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-primary text-uppercase">Our Services</h6>
            <h1 className="mb-5">
              Explore <span style={{ color: "var(--primary)" }}>Our</span>{" "}
              Services
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white">
                <div className="overflow-hidden mb-4">
                  <img
                    className="img-fluid"
                    src={s1}
                    alt="blog"
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <h5 className="mb-3">Internal Audits (ISM/ISPS/MLC)</h5>
                <p className="f-ps">
                  We at Marinesure are dedicated to ensure compliance with
                  international maritime safety and security standards. Our
                  comprehensive audits evaluate the effectiveness of your Safety
                  Management System (SMS) in accordance with the International
                  Safety Management (ISM) Code. We also assess your ship’s
                  adherence to the International Ship and Port Facility Security
                  (ISPS) Code, ensuring robust security measures are in place.
                  Additionally, we verify compliance with the Maritime Labour
                  Convention (MLC) requirements, safeguarding seafarers' rights
                  and welfare. Our expert auditors provide detailed reports with
                  actionable insights to enhance your vessel's operational
                  safety, security, and labor conditions. Trust Marinesure for
                  thorough and professional internal audits that support your
                  maritime operations and regulatory compliance.
                </p>
                <Link className="btn-slide mt-2" to="/service">
                  <i className="fa fa-arrow-right"></i>

                  <span>Read More</span>
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white">
                <div className="overflow-hidden mb-4">
                  <img
                    className="img-fluid"
                    src={s2}
                    alt="blog"
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <h5 className="mb-3">Navigational / VDR Audits</h5>
                <p className="f-ps">
                  Navigational and VDR Audits at MarineSure ensure the highest
                  standards of navigational safety and compliance on board
                  vessels. Our comprehensive audits thoroughly review navigation
                  practices, equipment functionality, and crew competency to
                  enhance operational efficiency and safety. The VDR (Voyage
                  Data Recorder) audits provide critical insights into voyage
                  data, enabling thorough analysis of incidents and operational
                  performance. By identifying potential risks and
                  non-conformities, we help clients achieve regulatory
                  compliance and operational excellence. MarineSure's
                  experienced auditors bring extensive maritime knowledge to
                  each audit, delivering actionable recommendations for
                  improvement. Trust us to enhance your navigational safety and
                  optimize your maritime operations. Your vessel's safety and
                  performance are our top priorities.
                </p>
                <Link className="btn-slide mt-2" to="/service">
                  <i className="fa fa-arrow-right"></i>

                  <span>Read More</span>
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item p-4 bg-white">
                <div className="overflow-hidden mb-4">
                  <img
                    className="img-fluid"
                    src={s3}
                    alt=""
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                </div>
                <h5 className="mb-3">Incident/Accident Investigation</h5>
                <p className="f-ps">
                  MarineSure's Incident/Accident Investigation service offers
                  thorough and impartial examinations of maritime incidents and
                  accidents. Our team of seasoned investigators utilizes
                  cutting-edge techniques to uncover root causes, ensuring
                  accurate and comprehensive reports. By adhering to
                  international standards, we provide actionable insights to
                  prevent future occurrences and enhance maritime safety. We
                  collaborate closely with clients, regulatory bodies, and other
                  stakeholders to deliver transparent and unbiased findings.
                  MarineSure’s commitment to excellence ensures that each
                  investigation is handled with the utmost professionalism and
                  confidentiality. Trust MarineSure to safeguard your operations
                  through meticulous and expert incident investigations.
                </p>
                <Link className="btn-slide mt-2" to="/service">
                  <i className="fa fa-arrow-right"></i>

                  <span>Read More</span>
                </Link>
              </div>
            </div>

            <div className="mt-5 col-12 d-flex justify-content-center">
              <Link to="/service" className="btn btn-secondary py-2 px-3 mb-5">
                Explore More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container overflow-hidden py-5 px-lg-0">
        <div className="container feature py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 feature-text wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <h6 className="text-primary text-uppercase mb-3">
                What We Specialize In
              </h6>
              <h1 className="mb-5">
                Ensuring Compliance, Safety, and Excellence
              </h1>
              <div className="d-flex mb-5 wow fadeInUp" data-wow-delay="0.3s">
                <i className="fa fa-globe text-primary fa-3x flex-shrink-0"></i>
                <div className="ms-4">
                  <h5>Ensuring Compliance, Safety, and Excellence</h5>
                  <p className="mb-0">
                    Our top priority is ensuring your operations meet the
                    highest standards of compliance and safety. Through
                    meticulous audits and inspections, we guarantee that your
                    vessels are not only safe but also excel in all regulatory
                    aspects.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-5 wow fadeIn" data-wow-delay="0.5s">
                <i className="fa-solid fa-shield-halved text-primary fa-3x flex-shrink-0"></i>

                <div className="ms-4">
                  <h5>Innovative Maritime Solutions for a Dynamic Industry</h5>
                  <p className="mb-0">
                    In an ever-evolving maritime industry, we provide
                    cutting-edge solutions tailored to your specific needs. From
                    advanced navigational audits to state-of-the-art training
                    programs, we stay ahead of industry trends to keep you
                    competitive.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-0 wow fadeInUp" data-wow-delay="0.7s">
                <i className="fa-solid fa-check text-primary fa-3x flex-shrink-0"></i>

                <div className="ms-4">
                  <h5>Exemplary Audits, Inspections, and Training</h5>
                  <p className="mb-0">
                    Marinesure Services Pvt Ltd offers unparalleled expertise in
                    audits, inspections, and training. Our thorough evaluations
                    and comprehensive training programs empower your crew and
                    ensure your vessels operate at peak performance
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 pe-lg-0 wow fadeInRight"
              data-wow-delay="0.1s"
              style={{ minHeight: 400 }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src={banner2}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Index;
