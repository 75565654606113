import React from "react";
import { Link } from "react-router-dom";
import about from "./../assests/images/about.png";
import { TbTargetArrow } from "react-icons/tb";
import { FaRegEye } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import s1 from "./../assests/images/service1.png";
import s2 from "./../assests/images/service-2.jpg";
import s3 from "./../assests/images/service-3.jpg";
const About = () => {
  return (
    <div>
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem" }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                <Link className="text-primary" to="/about">
                  About us
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End -->


    <!-- About Start --> */}
      <div className="container overflow-hidden pb-5 px-lg-0">
        <div className="container about  px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-12 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-primary text-uppercase mb-3">About Us</h6>
              <h3 className="mb-3">
              Marinesure Services{" "}
                <span style={{ color: "var(--primary)" }}>  Private </span> Limited
              </h3>
              <h6>CIN:  U50112UT2024PTC017204</h6>
              <p className="mb-5">
                Established in 2023, Marinesure Serices Pvt Ltd is a budding
                enterprise poised to make its mark in the maritime industry.
                Anchored by a seasoned team of professionally qualified,
                experienced, and dedicated staff, our vision is to cement our
                reputation as a trusted maritime partner by consistently
                delivering exemplary maritime services, encompassing audits,
                inspections, and comprehensive training programs.Our core
                objectives revolve around nurturing strong client relationships,
                ensuring unwavering regulatory compliance, and expanding our
                service portfolio to address the dynamic needs of the market. We
                are committed to continuous enhancement through strategic
                investments in technology, ongoing professional development, and
                the establishment of robust quality management systems. Driven
                by an unwavering commitment to excellence and innovation, we
                aspire to amplify our brand presence, broaden our clientele
                base, and ascend as a leading global provider of maritime
                solutions.
              </p>

              <div className="row g-4 mb-5">
                <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <TbTargetArrow className="fa fa-globe fa-3x text-secondary mb-3"/> 
                  <h5>Our Mission</h5>
                  <p className="m-0" style={{textAlign:'justify'}}>
                    Our mission is to deliver maritime solutions tailored to
                    cater to the specific requirements of our clients, ensuring
                    complete customer satisfaction.
                  </p>
                </div>
                <div className="col-lg-4  wow fadeIn" data-wow-delay="0.7s">
                <FaRegEye className="fa fa-globe fa-3x text-secondary mb-3"/> 
                  <h5>Our Vision</h5>
                  <p className="m-0" style={{textAlign:'justify'}}>
                    Our vision is to be an essential partner, offering
                    efficacious maritime solutions, encompassing all the needs
                    of our customers in the maritime industry.
                  </p>
                </div>
                <div className="col-lg-4  wow fadeIn" data-wow-delay="0.7s">
                <FaHandHoldingHeart className="fa fa-globe fa-3x text-secondary mb-3"/> 
                  <h5>Our values</h5>
                  <p className="m-0" style={{textAlign:'justify'}}>
                    Ownership, Integrity, Teamwork, and Customer Service—guide
                    us in delivering exceptional maritime services, ensuring
                    trust, collaboration, and satisfaction at every step.
                  </p>
                </div>
              </div>
            
            </div>
         
          </div>
        </div>
      </div>
   

      <div className="container-xxl " style={{ background: "#e5e5e5" }}>
        <div className="container  py-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="text-primary text-uppercase">Our Services</h6>
                <h1 className="mb-5">
                    Explore <span style={{ color: "var(--primary)" }}>Our</span>{" "}
                    Services
                </h1>
            </div>
            <div className="row g-4">
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4 bg-white">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={s1} alt="blog" style={{ height: "250px" , objectFit: "cover"
                                }} />
                        </div>
                        <h5 className="mb-3">Internal Audits (ISM/ISPS/MLC)</h5>
                    
                        <Link className="btn-slide mt-2" to="/service">
                            <i className="fa fa-arrow-right">
                              
                            </i>

                            <span>Read More</span>
                        </Link>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4 bg-white">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={s2} alt="blog" style={{ height: "250px" , objectFit: "cover"
                                }} />
                        </div>
                        <h5 className="mb-3">Navigational / VDR Audits</h5>
                   
                        <Link className="btn-slide mt-2" to="/service">
                            <i className="fa fa-arrow-right">
                            </i>
                            <span>Read More</span>
                        </Link>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4 bg-white">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={s3} alt="" style={{ height: "250px" , objectFit: "cover"
                                }} />
                        </div>
                        <h5 className="mb-3">Incident/Accident Investigation</h5>
                     
                        <Link  to="/service" className="btn-slide mt-2">
                            <i className="fa fa-arrow-right">
                            
                            </i>

                            <span>Read More</span>
                        </Link>
                    </div>
                </div>

                <div className="mt-5 col-12 d-flex justify-content-center">
                    <Link to="/service" className="btn btn-secondary py-2 px-3 mb-5">
                        View All Services
                    </Link>
                </div>
            </div>
        </div>
    </div>
     
    
    </div>
  );
};

export default About;
