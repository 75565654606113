import {React,useEffect} from 'react';
import { Routes, Route ,useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Index from "./components/index.js";
import About from './components/about.js';
import Service from './components/service.js';
import Contact from './components/contact.js';
import terms from './components/terms.js';
// import Contact from './components/.js';
import './components/custom.css';
import './components/bootstrap.css';

export default function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

  return (
    <div>
      <Header />
      <ScrollToTop />
      <Routes >
        <Route path="/" Component={Index} />
        <Route path="/about" Component={About} />
        <Route path="/service" Component={Service} />
        <Route path="/contact" Component={Contact} />
        <Route path="/terms" Component={terms} />
      </Routes>
      <Footer/>
    </div>
  );
}
