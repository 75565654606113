import React from "react";
import { BsEnvelopeFill } from "react-icons/bs";
import { BsPinMapFill } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import logo from "./../assests/images/logo (3).png";
const Footer = () => {
  return (
    <div>
      <div
        className="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
        data-wow-delay="0.1s"
        style={{ marginTop: "6rem" }}
      >
        <div className="container-fluid px-5 py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6 mt-4">
              <img
                src={logo}
                alt="blog"
                style={{ width: "100%", height: "90px" }}
              ></img>

              <p className="text-white mt-2" style={{ fontSize: "14px" }}>
                Marinesure Serices Pvt Ltd is a budding
                enterprise with the vision to cement reputation as a trusted
                maritime partner by consistently delivering exemplary maritime
                services, encompassing audits, inspections, and comprehensive
                training programs.
              </p>
            </div>
            <div className="col-lg-3 col-md-6  d-flex align-items-start justify-content-center">
              <div className="d-inline-block">
                <h4 className="text-light mb-4">Quick Links</h4>
                <Link className="btn btn-link" to="/about">
                  About Us
                </Link>
                <Link className="btn btn-link" to="/service">
                  Our Services
                </Link>
                <Link className="btn btn-link" to="/contact">
                  Contact
                </Link>
                <Link className="btn btn-link" to="/terms">
                  Terms & Condition
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Services</h4>
              <Link to="/service#internal-audits" className="btn btn-link">
                Internal Audits (ISM/ISPS/MLC)
              </Link>
              <Link to="/service#navigational-audits" className="btn btn-link">
                Navigational / VDR Audits
              </Link>
              <Link
                to="/service#incident-investigation"
                className="btn btn-link"
              >
                Incident/Accident Investigation{" "}
              </Link>
              <Link to="/service#condition-inspection" className="btn btn-link">
                Condition Inspection (Pre Purchase){" "}
              </Link>
              <Link to="/service" className="btn btn-link"   style={{ color: "#0077B5" }}>
                 More
              </Link>
            </div>

            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Address</h4>
              <p className="mb-2 d-flex align-items-baseline justify-content-start">
                {" "}
                <i className="fa-solid fa-location-dot me-3" />
                Regd Office: Johri Paragana,
                Dehradun-248001, India
              </p>
              <p className="mb-2  d-flex align-items-baseline justify-content-start">
                {" "}
                <BsFillTelephoneFill className="me-3" />
                +91 7668654916
              </p>
              <p className="mb-2   d-flex align-items-baseline justify-content-start">
                <BsEnvelopeFill className="me-3" />
                info@marinesure.in
              </p>
              <Link className="" to="">
                <p
                  className="mb-2  d-flex align-items-baseline justify-content-start"
                  style={{ color: "#0077B5" }}
                >
                  <i className="fab fa-linkedin-in me-3" />
                  Linkdin
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; 2024,
                <Link className="border-bottom text-primary" href="#">
                  Marinesure
                </Link>{" "}
                , All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                Designed By
                <a
                  className="border-bottom text-secondary ms-1"
                  href="https://www.magnaopussoftware.in/"
                >
                  Magna Opus
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
