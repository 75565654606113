import React from 'react'
import { Link } from 'react-router-dom'

const terms = () => {
  return (
    <div>
          <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem" }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
         Terms & Conditions
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                <Link className="text-primary" to="/about">
                Terms & Conditions
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div> 


      <div className='container'>
      <div className="container about  px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-12 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-primary text-uppercase mb-3">Terms and Conditions</h6>
              <h3 className="mb-3">
           
      Navigating {" "}
                <span style={{ color: "var(--primary)" }}>  Compliance  </span> with Confidence
              </h3>
        


 <h4>1. General</h4>

    <p>1.1. These Terms and Conditions govern the provision of services by Marinesure Services Pvt Ltd (“the Company”) to its clients (“the Client”).</p>
    <p>1.2. By engaging the Company’s services, the Client agrees to comply with and be bound by these Terms and Conditions.</p>
    <h4>2. Services</h4>
    <p>2.1. The Company provides the following maritime services:</p>
    <ul style={{listStyle:'circle'}}>

   <li><p>Internal Audits (ISM/ISPS/MLC)</p></li>
  <li><p>Navigational / VDR Audits</p></li>
  <li><p>Incident/Accident Investigation</p></li>
  <li><p>Condition Inspection (Pre Purchase)</p></li>
  <li><p>Pre Fixture Inspection</p></li>
  <li><p>SMS Documentation (Gap Analysis)</p></li>
  <li><p>Pre PSC Inspections</p></li>
  <li><p>Pre Rightship Inspections</p></li>
  <li><p>Pre SIRE Inspections</p></li>
  <li><p>Training and Development</p></li>
  </ul>

  <p>2.2. The specific services to be provided will be detailed in a separate Service Agreement between the Company and the Client.</p>
  <h4>3. Fees and Payment</h4>
  <p>3.1. The Client agrees to pay the fees for the services as specified in the Service Agreement.</p>
  <p>3.2. Payment terms are net thirty (30) days from the date of invoice unless otherwise agreed in writing.</p>
  <p>3.3. In the event of late payment, the Company reserves the right to charge interest at a rate of 1.5% per month on the outstanding balance.</p>
  <h4> 4. Cancellation and Rescheduling</h4>
  <p>4.1. The Client may cancel or reschedule services with at least seven (7) days' notice without incurring any charges.</p>
  <p>4.2. Cancellations or rescheduling with less than seven (7) days' notice may incur a fee of up to 50% of the total service fee.</p>
  <h4>5. Confidentiality</h4>
  <p>5.1. Both parties agree to keep all information received from the other party confidential and not to use it for any purpose other than the provision of services.</p>
  <p>5.2. This confidentiality obligation does not apply to information that is already in the public domain or that the receiving party is required to disclose by law.</p>
  <h4>6. Liability</h4>
  <p>6.1. The Company will use all reasonable endeavors to ensure the accuracy and quality of the services provided.</p>
  <p>6.2. The Company’s liability for any claim arising out of the provision of services is limited to the amount of fees paid by the Client for those services.</p>
  <p>6.3. The Company is not liable for any indirect, incidental, or consequential damages arising from the use of its services.</p>
  <p>7. Force Majeure</p>
  <p>7.1. The Company is not liable for any failure to perform its obligations under these Terms and Conditions if such failure is due to circumstances beyond its reasonable control, including but not limited to acts of God, war, strikes, and other industrial disputes, natural disasters, or interruption in the supply of utilities.</p>
  <h4>8. Governing Law</h4>
<p> 8.1. These Terms and Conditions are governed by and construed in accordance with the laws of [Uttarakhand ,India].</p>
<p>8.2. Any disputes arising out of or in connection with these Terms and Conditions will be subject to the exclusive jurisdiction of the courts of [Uttarakhand ,India].</p>
 <h4>9. Amendments</h4>
<p>9.1. The Company reserves the right to amend these Terms and Conditions at any time. The Client will be notified of any changes in writing.</p>
<p>9.2. Continued use of the Company’s services following any amendment constitutes acceptance of the revised Terms and Conditions.</p>
<p>10. Contact Information</p>
 <p>10.1. For any questions or concerns regarding these Terms and Conditions, please contact us at:
  <br />
   Marinesure Services Pvt Ltd
   <br />
  Johri Paragana, Dehradun-248001, India
  <br />
  +91 8146724747
  <br />
  info@marinesure.in</p>
            

          
            
            </div>
         
          </div>
        </div>
         
      </div>

    </div>
  )
}

export default terms
