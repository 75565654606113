import React from "react";
import { Link } from "react-router-dom";
import s1 from "./../assests/images/service1.png";
import s2 from "./../assests/images/service-2.jpg";
import s3 from "./../assests/images/service-3.jpg";
import s4 from "./../assests/images/s4.png";
import s5 from "./../assests/images/s5.jpg";
import s6 from "./../assests/images/s6.png";
import s7 from "./../assests/images/s7.png";
import s8 from "./../assests/images/s8.png";
import s9 from "./../assests/images/s9.png";
import s10 from "./../assests/images/s10.png";
const Service = () => {
  return (
    <div>
      <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem" }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Our Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                <Link className="text-primary" to="/service">
                  Services
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl ">
        <div className="container  ">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-primary text-uppercase">Our Services</h6>
            <h1 className="mb-5">
              Explore <span style={{ color: "var(--primary)" }}>Our</span>{" "}
              Services
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="internal-audits"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s1}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Internal Audits (ISM/ISPS/MLC)</h5>
                    <p>
                      We at Marinesure are dedicated to ensure compliance with
                      international maritime safety and security standards. Our
                      comprehensive audits evaluate the effectiveness of your
                      Safety Management System (SMS) in accordance with the
                      International Safety Management (ISM) Code. We also assess
                      your ship’s adherence to the International Ship and Port
                      Facility Security (ISPS) Code, ensuring robust security
                      measures are in place. Additionally, we verify compliance
                      with the Maritime Labour Convention (MLC) requirements,
                      safeguarding seafarers' rights and welfare. Our expert
                      auditors provide detailed reports with actionable insights
                      to enhance your vessel's operational safety, security, and
                      labor conditions. Trust Marinesure for thorough and
                      professional internal audits that support your maritime
                      operations and regulatory compliance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="navigational-audits"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s2}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Navigational / VDR Audits</h5>
                    <p>
                      Navigational and VDR Audits at MarineSure ensure the
                      highest standards of navigational safety and compliance on
                      board vessels. Our comprehensive audits thoroughly
                      review navigation practices, equipment functionality, and
                      crew competency to enhance operational efficiency and
                      safety. The VDR (Voyage Data Recorder) audits provide
                      critical insights into voyage data, enabling thorough
                      analysis of incidents and operational performance. By
                      identifying potential risks and non-conformities, we help
                      clients achieve regulatory compliance and operational
                      excellence. MarineSure's experienced auditors bring
                      extensive maritime knowledge to each audit, delivering
                      actionable recommendations for improvement. Trust us to
                      enhance your navigational safety and optimize your
                      maritime operations. Your vessel's safety and performance
                      are our top priorities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="incident-investigation"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s3}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Incident/Accident Investigation</h5>
                    <p>
                      MarineSure's Incident/Accident Investigation service
                      offers thorough and impartial examinations of maritime
                      incidents and accidents. Our team of seasoned
                      investigators utilizes cutting-edge techniques to uncover
                      root causes, ensuring accurate and comprehensive reports.
                      By adhering to international standards, we provide
                      actionable insights to prevent future occurrences and
                      enhance maritime safety. We collaborate closely with
                      clients, regulatory bodies, and other stakeholders to
                      deliver transparent and unbiased findings. MarineSure’s
                      commitment to excellence ensures that each investigation
                      is handled with the utmost professionalism and
                      confidentiality. Trust MarineSure to safeguard your
                      operations through careful and expert incident
                      investigations.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="condition-inspection"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s4}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">
                      Condition Inspection (Pre Purchase){" "}
                    </h5>
                    <p>
                      Marinesure offers comprehensive Condition Inspection (Pre
                      Purchase) services to help clients make informed decisions
                      when acquiring a vessel. Our expert team conducts thorough
                      inspections, assessing the ship's structural integrity,
                      machinery, and systems. We provide detailed reports
                      highlighting potential issues and necessary repairs,
                      ensuring transparency and minimizing risks. This service
                      aids in negotiating fair prices and planning for future
                      maintenance. With our expertise, clients gain confidence
                      in their investment, safeguarding their financial
                      interests. Trust Marinesure for reliable, in-depth
                      pre-purchase inspections that enhance your maritime
                      ventures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="pre-fixture-inspection"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s5}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Pre Fixture Inspection </h5>
                    <p>
                      Pre Fixture Inspection is a critical service provided by
                      Marinesure, designed to ensure vessels are in optimal
                      condition before contract agreements. This comprehensive
                      inspection covers all aspects of the vessel's
                      functionality and safety. Our experts conduct detailed assessment of
                      the structural integrity, machinery, and equipment. By
                      identifying potential issues early, we help clients avoid
                      costly repairs and operational delays. Our detailed
                      reports provide valuable insights and recommendations.
                      Trust Marinesure for thorough, unbiased pre fixture
                      inspections. We ensure your vessels are always ready for
                      smooth and efficient operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="sms-documentation"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s6}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">SMS Documentation (Gap Analysis)</h5>
                    <p>
                      MarineSure offers comprehensive SMS Documentation (Gap
                      Analysis) services to ensure your Safety Management System
                      (SMS) is robust and compliant with international
                      standards. Our experts review your existing
                      SMS documentation, identifying any gaps and areas for
                      improvement. We provide detailed reports and actionable
                      recommendations to bridge these gaps, enhancing the
                      overall safety and efficiency of your maritime operations.
                      By addressing deficiencies, we help you mitigate risks and
                      ensure compliance with ISM Code requirements. Our service
                      ensures your SMS is not only compliant but also
                      effectively implemented, promoting a culture of continuous
                      improvement and operational excellence. Trust MarineSure
                      to optimize your SMS documentation and maintain the
                      highest standards of safety and performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="pre-psc-inspections"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s7}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Pre PSC Inspections</h5>
                    <p>
                      Pre PSC (Port State Control) Inspections offered by
                      Marinesure are designed to ensure your vessel meets all
                      regulatory requirements before entering a port. Our expert
                      auditors conduct thorough inspections to identify and
                      rectify potential deficiencies, minimizing the risk of
                      detention. By simulating actual PSC inspections, we help
                      you achieve compliance with international maritime
                      conventions. Our comprehensive reports and actionable
                      recommendations enhance your vessel's safety and
                      operational readiness. With Marinesure, you can navigate
                      global ports with confidence, knowing your vessel is fully
                      prepared. Trust our experienced team to safeguard your
                      operations and reputation. Ensure smooth port entry and
                      avoid costly delays with our reliable Pre PSC Inspection
                      service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="pre-rightship-inspections"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s8}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Pre Rightship Inspections</h5>
                    <p>
                      MarineSure offers comprehensive Pre Rightship Inspections
                      to ensure your vessels meet the stringent standards
                      required for Rightship vetting. Our expert team conducts
                      thorough evaluations, covering safety, environmental
                      compliance, and operational efficiency. We meticulously
                      inspect the vessel's structure, machinery, and
                      documentation, providing detailed reports and actionable
                      insights. With MarineSure's Pre Rightship Inspections, you
                      can mitigate risks, enhance safety, and improve your
                      vessel's performance. Our service ensures that your fleet
                      is fully prepared for successful Rightship audits,
                      promoting smoother operations and increased chartering
                      opportunities. Trust MarineSure to elevate your maritime
                      standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="pre-sire-inspections"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s9}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Pre SIRE Inspections</h5>
                    <p>
                      Pre SIRE Inspections: MarineSure offers comprehensive Pre
                      SIRE (Ship Inspection Report Programme) Inspections to
                      ensure your vessel meets industry standards and passes the
                      rigorous SIRE inspections. Our experienced auditors
                      conduct thorough assessments, identifying potential
                      non-compliances and areas for improvement. We provide
                      detailed reports and actionable recommendations to address
                      any issues, enhancing your vessel’s safety and operational
                      efficiency. With MarineSure’s Pre SIRE Inspections, you
                      can confidently present your vessel for official SIRE
                      audits, minimizing the risk of costly delays and ensuring
                      regulatory compliance. Trust MarineSure to prepare your
                      vessel for success in SIRE inspections.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-lg-12 wow fadeInUp"
              id="training-development"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4 bg-white row">
                <div className="overflow-hidden mb-4 col-lg-5 col-12 col-md-12">
                  <img
                    className="img-fluid"
                    src={s10}
                    alt="blog"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="overflow-hidden  col-lg-7 col-12 col-md-12">
                  <div className="d-flex align-items-start justify-content-center flex-column">
                    <h5 className="mb-3 ">Training and Development</h5>
                    <p>
                      Marinesure excels in providing comprehensive and tailored
                      training programs in maritime regulations, vessel
                      operations, crew management, and innovative technologies
                      to meet customer demands and ensure high standards of
                      safety and compliance. At Marinesure we shape the future
                      of maritime leadership through education.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
