import React, { useState } from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [message, setMessage] = useState();

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formState ==>", formState);
    fetch("https://www.marinesure.in/marine_contact_form/send-email.php", {
      method: "POST",
      body: JSON.stringify(formState),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response ==>", response);
        if (response.status === "success") {
          setMessage(response.message);
          setFormState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else if (response.status === "fail") {
          setMessage(response.message);
        }
      });
  };

  return (
    <div>
      <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem" }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                <Link className="text-primary" to="/contact">
                  contact
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container overflow-hidden  px-lg-0">
        <div className="container contact-page py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-md-6 contact-form wow fadeIn"
              data-wow-delay="0.1s"
            >
              <h6 className="text-secondary text-uppercase">Get In Touch</h6>
              <h1 className="mb-4">Contact For Any Query</h1>
              <p className="mb-4"></p>
              <div className="bg-light p-4">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          type="text"
                          value={formState.name}
                          onChange={changeHandler}
                          id="name"
                          name="name"
                          placeholder="Your Name"
                        ></input>
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          value={formState.email}
                          onChange={changeHandler}
                          id="email"
                          name="email"
                          placeholder="Your Email"
                        ></input>
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          value={formState.phone}
                          onChange={changeHandler}
                          id="phone"
                          name="phone"
                          placeholder="Your Phone No."
                        ></input>
                        <label htmlFor="phone">Your Phone No.</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          value={formState.subject}
                          onChange={changeHandler}
                          id="subject"
                          name="subject"
                          placeholder="Subject"
                        ></input>
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          value={formState.message}
                          onChange={changeHandler}
                          id="message"
                          name="message"
                          style={{ height: "100px" }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>

                <div style={{ padding: 10, color: "green" }}>
                  <p>{message}</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 pe-lg-0 wow fadeInRight"
              data-wow-delay="0.1s"
            >
              <div className="position-relative h-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13768.563535965492!2d78.05041102501157!3d30.37535711739606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3908d7b40e91f781%3A0xf0efb38f8e959704!2sJohri%2C%20Malsi%2C%20Dehradun%2C%20Uttarakhand%20248014!5e0!3m2!1sen!2sin!4v1720780895516!5m2!1sen!2sin"
               
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: "cover" }}
                  allowfullscreen=""
                   title="map"
                  loading="lazy"
                   aria-hidden="false"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
