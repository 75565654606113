import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './custom.css'; // Ensure your CSS file with .sticky-top styles is imported
import logo from '../assests/images/logo (3).png';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  const navigate = useNavigate(); // Use useNavigate hook to get navigation function

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownLinkClick = (id) => {
    // Check if the user is already on the services page
    if (window.location.pathname === '/service') {
      // Scroll to the element with the corresponding id
      const element = document.getElementById(id);
      if (element) {
        const offset = element.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2 - element.clientHeight / 2);
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        });
      }
    } else {
      // Navigate to the services page and append the hash
      navigate(`/service#${id}`);
    }
  };

  return (
    <header className={`navbar navbar-expand-lg bg-white navbar-light shadow border-top border-2 header ${isSticky ? 'sticky' : ''} p-0`}>
      <NavLink to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
        <img src={logo} style={{ objectFit: 'contain', width: '100%', height: '60px' }} alt="Logo" />
      </NavLink>
      <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <NavLink to="/" className="nav-item nav-link" >Home</NavLink>
          <NavLink to="/about" className="nav-item nav-link" >About</NavLink>
          <div className="nav-item dropdown">
            <NavLink to="/service" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</NavLink>
            <div className="dropdown-menu fade-up m-0">
              <Link to="/service#internal-audits" className="dropdown-item" onClick={() => handleDropdownLinkClick('internal-audits')}>Internal Audits (ISM/ISPS/MLC)</Link>
              <Link to="/service#navigational-audits" className="dropdown-item" onClick={() => handleDropdownLinkClick('navigational-audits')}>Navigational / VDR Audits</Link>
              <Link  to="/service#incident-investigation" className="dropdown-item" onClick={() => handleDropdownLinkClick('incident-investigation')}>Incident/Accident Investigation </Link>
              <Link  to="/service#condition-inspection" className="dropdown-item" onClick={() => handleDropdownLinkClick('condition-inspection')}>Condition Inspection (Pre Purchase) </Link>
              <Link  to="/service#pre-fixture-inspection" className="dropdown-item" onClick={() => handleDropdownLinkClick('pre-fixture-inspection')} >Pre Fixture Inspection </Link>
              <Link  to="/service#sms-documentation" className="dropdown-item" onClick={() => handleDropdownLinkClick('sms-documentation')} >SMS Documentation (Gap Analysis) </Link>
              <Link  to="/service#pre-psc-inspections" className="dropdown-item" onClick={() => handleDropdownLinkClick('pre-psc-inspections')}>Pre PSC Inspections </Link>
              <Link  to="/service#pre-rightship-inspections" className="dropdown-item" onClick={() => handleDropdownLinkClick('pre-rightship-inspections')}>Pre Rightship Inspections </Link>
              <Link to="/service#pre-sire-inspections" className="dropdown-item" onClick={() => handleDropdownLinkClick('pre-sire-inspections')} >Pre SIRE Inspections </Link>
              <Link to="/service#training-development" className="dropdown-item" onClick={() => handleDropdownLinkClick('pre-sire-inspections')}  >Training and Development </Link>
            </div>
          </div>
              <NavLink to="/contact" className="nav-item nav-link" >Contact</NavLink>
       
        </div>
        <h5 className="m-0 pe-lg-5 d-none d-lg-block">
          <i className="fa fa-phone text-primary me-2"></i>+91 7668654916
        </h5>
      </div>
    </header>
  );
};

export default Header;
